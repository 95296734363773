import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// i18n
import './locales/i18n';
// scroll bar
import 'simplebar-react/dist/simplebar.min.css';
// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
// ----------------------------------------------------------------------
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// locales
import { useAuthContext } from './auth/useAuthContext';
import ThemeLocalization from './locales';
// components
import SnackbarProvider from './components/snackbar';
import ScrollToTop from './components/scroll-to-top';
import { MotionLazyContainer } from './components/animate';
import { ThemeSettings, SettingsProvider } from './components/settings';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Provider as ReduxProvider } from 'react-redux';
import { dispatch, store } from './redux/store';
import GetPermissions from './auth/GetPermissions';
import { AbilityContext } from './auth/can';
import { useEffect, useState } from 'react';
import { getAllActions } from './redux/slices/action/actionThunk';
// ----------------------------------------------------------------------
export default function App() {
    const { user } = useAuthContext();
    const roles = user?.roles;
    const [actions, setActions] = useState([]);
    useEffect(() => {
        user && dispatch(getAllActions()).then((res) => {
            setActions(res?.payload?.data);
        });
    }, [user]);
    const listOfPermissions = GetPermissions(roles, actions);
    return (_jsx(HelmetProvider, { children: _jsx(AbilityContext.Provider, { value: listOfPermissions, children: _jsx(ReduxProvider, { store: store, children: _jsx(LocalizationProvider, { dateAdapter: AdapterDateFns, children: _jsx(SettingsProvider, { children: _jsxs(BrowserRouter, { children: [_jsx(ScrollToTop, {}), _jsx(MotionLazyContainer, { children: _jsx(ThemeProvider, { children: _jsx(ThemeSettings, { children: _jsx(ThemeLocalization, { children: _jsx(SnackbarProvider, { children: _jsx(Router, {}) }) }) }) }) })] }) }) }) }) }) }));
}
